<template>
    <div class="recharge-page">
      <BackHomeButton />
      <div class="form-container">
        <h1>积分充值</h1>
        <form @submit.prevent="submitRecharge" class="recharge-form">
          <div class="form-group">
            <label for="user">选择用户</label>
            <div class="select-container">
              <input 
                type="text"
                v-model="searchQuery"
                class="form-input search-input"
                placeholder="输入用户名或ID搜索"
                @input="filterUsers"
              />
              <div v-if="showDropdown && filteredUsers.length" class="dropdown-list">
                <div 
                  v-for="user in filteredUsers" 
                  :key="user.id"
                  class="dropdown-item"
                  @click="selectUser(user)"
                >
                  {{ user.username }} (ID: {{ user.id }})
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-group">
            <label for="amount">充值金额</label>
            <select 
              id="amount" 
              v-model="formData.amount"
              class="form-input"
              required
            >
              <option value="">请选择充值金额</option>
              <option value="10">10元</option>
              <option value="20">20元</option>
              <option value="50">50元</option>
              <option value="100">100元</option>
              <option value="200">200元</option>
              <option value="300">300元</option>
            </select>
          </div>
  
          <div class="form-group">
            <label for="verifyCode">验证码</label>
            <div class="verify-code-container">
              <input 
                type="text" 
                id="verifyCode" 
                v-model="formData.verifyCode"
                class="form-input verify-input"
                required
                maxlength="16"
              >
            </div>
          </div>
  
          <button type="submit" class="submit-btn" :disabled="isSubmitting">
            {{ isSubmitting ? '提交中...' : '确认充值' }}
          </button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import BackHomeButton from '@/components/BackHomeButton.vue';
  
  export default {
    name: 'Recharge',
    components: {
      BackHomeButton
    },
    data() {
      return {
        users: [],
        selectedUser: null,
        searchQuery: '',
        showDropdown: false,
        filteredUsers: [],
        formData: {
          userId: '',
          username: '',
          amount: '',
          verifyCode: ''
        },
        isSubmitting: false
      };
    },
    methods: {
      async getAllUsers() {
        try {
          const token = localStorage.getItem('access_token');
          const response = await axios.get('/api/all-users', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.users = response.data.users;
          this.filteredUsers = [...this.users];
        } catch (error) {
          console.error('Failed to get users:', error);
        }
      },
      filterUsers() {
        const query = this.searchQuery.toLowerCase();
        this.filteredUsers = this.users.filter(user => 
          user.username.toLowerCase().includes(query) || 
          user.id.toString().includes(query)
        );
        this.showDropdown = true;
      },
      selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = `${user.username} (ID: ${user.id})`;
        this.formData.userId = user.id;
        this.formData.username = user.username;
        this.showDropdown = false;
      },
      async submitRecharge() {
        if (!this.formData.userId || !this.formData.amount || !this.formData.verifyCode) {
          alert('请填写完整信息');
          return;
        }
  
        this.isSubmitting = true;
        try {
          const token = localStorage.getItem('access_token');
          const response = await axios.post('/api/recharge', this.formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          alert('充值成功！');
          this.formData.amount = '';
          this.formData.verifyCode = '';
          this.selectedUser = null;
        } catch (error) {
          console.error('Recharge failed:', error);
          alert('充值失败，请稍后重试');
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    mounted() {
      this.getAllUsers();
      // 点击外部关闭下拉框
      document.addEventListener('click', (e) => {
        if (!e.target.closest('.select-container')) {
          this.showDropdown = false;
        }
      });
    }
  };
  </script>
  
  <style scoped>
  .recharge-page {
    min-height: 100vh;
    background: linear-gradient(to bottom, #1a202c, #2d3748);
    padding: 2rem 1rem;
  }
  
  .form-container {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #2d3748;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #4CAF50;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
    width: 100%;
  }
  
  label {
    display: block;
    color: #e0e0e0;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
  
  .form-input {
    width: 100%;
    padding: 0.75rem;
    background-color: #1a202c;
    border: 1px solid #4a5568;
    border-radius: 5px;
    color: #e0e0e0;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  .verify-code-container {
    display: flex;
    gap: 0.5rem;
  }
  
  .verify-input {
    flex: 1;
  }
  
  .verify-btn {
    padding: 0 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    min-width: 100px;
  }
  
  .verify-btn:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
  
  .submit-btn {
    width: 100%;
    padding: 0.75rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    transition: background-color 0.3s;
  }
  
  .submit-btn:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .submit-btn:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
  
  /* 移动端适配 */
  @media (max-width: 480px) {
    .form-container {
      padding: 1.5rem;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .verify-btn {
      min-width: 90px;
      font-size: 0.9rem;
    }
  }
  
  .select-container {
    position: relative;
    width: 100%;
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem;
    background-color: #1a202c;
    border: 1px solid #4a5568;
    border-radius: 5px;
    color: #e0e0e0;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: #2d3748;
    border: 1px solid #4a5568;
    border-top: none;
    border-radius: 0 0 5px 5px;
    z-index: 1000;
    box-sizing: border-box;
  }
  
  .dropdown-item {
    padding: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .dropdown-item:hover {
    background-color: #4a5568;
  }
  
  /* 美化滚动条 */
  .dropdown-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .dropdown-list::-webkit-scrollbar-track {
    background: #1a202c;
  }
  
  .dropdown-list::-webkit-scrollbar-thumb {
    background: #4a5568;
    border-radius: 4px;
  }
  
  .dropdown-list::-webkit-scrollbar-thumb:hover {
    background: #718096;
  }
</style>