<template>
    <div v-if="visible" class="payment-modal">
      <div class="modal-content">
        <button class="close-btn" @click="$emit('close')">×</button>
        <h2>请用微信扫码支付</h2>
        <p class="amount">支付金额：¥{{ amount }}</p>
        <div class="qr-code">
          <img :src="getQRCodeUrl()" alt="支付二维码" />
        </div>
        <div class="payment-tips">
          <div class="user-id-notice">
            <p>支付请务必备注用户ID</p>
            <p class="user-id">您的用户ID是：<span>{{ userID }}</span></p>
          </div>
          <p>管理员微信号：gnayji</p>
          <p class="warning">* 支付成功后积分将在2-3分钟内到账</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PaymentModal',
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      amount: {
        type: Number,
        required: true,
        default: 0
      }
    },
    data() {
      return {
        userID: ''
      };
    },
    methods: {
      getQRCodeUrl() {
        return `/payment-qr-codes/${this.amount}.jpg`;
      },
      async fetchUserInfo() {
        try {
          const response = await fetch('/api/user_info', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          });
          if (response.ok) {
            const data = await response.json();
            this.userID = data.user_id;
          } else {
            console.error('获取用户信息失败');
          }
        } catch (error) {
          console.error('获取用户信息时发生错误:', error);
        }
      }
    },
    mounted() {
      this.fetchUserInfo();
    }
  };
  </script>

  <style scoped>
  .payment-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content {
    background-color: #1e1e1e;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    max-width: 400px;
    width: 90%;
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #e0e0e0;
    font-size: 24px;
    cursor: pointer;
  }
  h2 {
    color: #4CAF50;
    text-align: center;
    margin-bottom: 1rem;
  }
  .amount {
    text-align: center;
    font-size: 1.2rem;
    color: #FFD700;
    margin-bottom: 1.5rem;
  }
  .qr-code {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .qr-code img {
    max-width: 200px;
    width: 100%;
  }
  .payment-tips {
    text-align: center;
    color: #b0b0b0;
  }
  .warning {
    color: #ff6b6b;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  .user-id-notice {
    background-color: #2d3748;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    border: 1px solid #4CAF50;
  }
  .user-id-notice p {
    margin: 0.5rem 0;
    color: #e0e0e0;
    font-weight: bold;
  }
  .user-id {
    color: #FFD700 !important;
    font-size: 1.2rem;
  }
  .user-id span {
    color: #4CAF50;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
    background-color: rgba(76, 175, 80, 0.1);
    border-radius: 4px;
  }
  </style>