<template>
  <router-link to="/" class="back-home-button">
    <span class="icon">&#8592;</span> 返回首页
  </router-link>
</template>

<script>
export default {
  name: 'BackHomeButton'
}
</script>

<style scoped>
.back-home-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
  position: absolute;
  top: 20px;
  left: 20px;
}

.back-home-button:hover {
  background-color: #45a049;
}

.icon {
  margin-right: 8px;
  font-size: 1.2em;
}
</style>
