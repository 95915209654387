<template>
  <div class="user-info">
    <div class="user-avatar" @click="toggleUserMenu">
      <img :src="userAvatar" alt="User Avatar" />
    </div>
    <div v-if="isMenuOpen" class="user-menu">
      <div class="user-menu-header">
        <img :src="userAvatar" alt="User Avatar" />
        <span>{{ username }}</span>
      </div>
      <div class="user-menu-content">
        <p><strong>用户ID:</strong> {{ user_id }}</p>
        <p><strong>用户名:</strong> {{ username }}</p>
        <p><strong>邮箱:</strong> {{ email }}</p>
        <p><strong>剩余积分:</strong> {{ points }}</p>
        <p><strong>会员等级:</strong> {{ membershipLevel }}</p>
      </div>
      <button @click="logout" class="logout-button">登出</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  data() {
    return {
      isMenuOpen: false,
      username: '',
      user_id: '',
      email: '',
      points: 0,
      membershipLevel: '',
      userAvatar: require('@/assets/images/default-avatar.png')
    };
  },
  methods: {
    toggleUserMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    logout() {
      localStorage.removeItem('access_token');
      if (this.$route.path !== '/') {
        this.$router.push('/');
      } else {
        window.location.reload();
      }
    },
    async fetchUserInfo() {
      try {
        const response = await fetch('/api/user_info', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          this.username = data.username;
          this.user_id = data.user_id;
          this.email = data.email;
          this.points = data.current_points;
          this.membershipLevel = data.membership_level;
          this.userAvatar = data.avatar || require('@/assets/images/default-avatar.png');
        } else {
          console.error('获取用户信息失败');
        }
      } catch (error) {
        console.error('获取用户信息时发生错误:', error);
      }
    }
  },
  mounted() {
    this.fetchUserInfo();
  }
};
</script>

<style scoped>
.user-info {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-menu {
  position: absolute;
  top: 50px;
  right: 0;
  width: 250px;
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 1rem;
  color: #e0e0e0;
}
.user-menu-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #444;
  padding-bottom: 0.5rem;
}
.user-menu-header img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.user-menu-content p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
}
.logout-button {
  width: 100%;
  padding: 0.5rem;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}
.logout-button:hover {
  background-color: #c53030;
}
</style>