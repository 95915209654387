<template>
  <div id="app">
    <UserInfo v-if="isLoggedIn" />
    <router-view></router-view>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import UserInfo from '@/components/UserInfo.vue';

export default {
  name: 'App',
  components: {
    UserInfo
  },
  setup() {
    const isLoggedIn = ref(false);

    const checkLoginStatus = async () => {
      try {
        const response = await fetch('/api/check_login', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          isLoggedIn.value = data.logged_in;
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };

    onMounted(checkLoginStatus);

    return {
      isLoggedIn
    };
  }
};
</script>

<style>
html, body {
  background-color: #121212;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
