<template>
  <div class="welcome-page">
    <h1>欢迎来到 AI 模型训练页面</h1>
    <p>功能开发中，敬请期待！</p>
  </div>
</template>

<script>
export default {
  name: 'ModelTraining'
}
</script>
