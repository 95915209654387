<template>
    <div class="chat-container">
      <UserInfo />
      <div class="header">
        <BackHomeButton />
        <h1>ChatGPT</h1>
      </div>
      
      <div class="chat-box">
        <div class="messages" ref="messagesContainer">
          <div v-for="(message, index) in messages" 
            :key="index" 
            :class="['message', message.role]"
          >
            <div class="message-content" v-html="message.content"></div>
          </div>
        </div>
        
        <div class="input-area">
          <textarea 
            v-model="userInput" 
            @keyup.enter="sendMessage"
            :disabled="isLoading"
            placeholder="输入消息，按Enter发送..."
            rows="3"
          ></textarea>
          <button 
            @click="sendMessage" 
            :disabled="isLoading || !userInput.trim()"
          >
            {{ isLoading ? '发送中...' : '发送' }}
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BackHomeButton from '@/components/BackHomeButton.vue';
  import UserInfo from '@/components/UserInfo.vue';
  
  export default {
    name: 'ChatGPT',
    components: {
      BackHomeButton,
      UserInfo
    },
    data() {
      return {
        messages: [],
        userInput: '',
        isLoading: false
      };
    },
    methods: {
      // 简单的HTML转义函数
      escapeHtml(text) {
        const div = document.createElement('div');
        div.textContent = text;
        return div.innerHTML;
      },
      async sendMessage() {
        if (!this.userInput.trim() || this.isLoading) return;
  
        const userMessage = this.userInput.trim();
        this.messages.push({
          role: 'user',
          content: this.escapeHtml(userMessage)
        });
        this.userInput = '';
        this.isLoading = true;
  
        try {
          const token = localStorage.getItem('access_token');
          const response = await fetch('/api/chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              message: userMessage
            })
          });
  
          if (!response.ok) throw new Error('Network response was not ok');
  
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let assistantMessage = '';
  
          // 创建新的助手消息
          this.messages.push({
            role: 'assistant',
            content: ''
          });
  
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
  
            const chunk = decoder.decode(value);
            assistantMessage += chunk;
            
            // 更新最后一条消息的内容
            this.messages[this.messages.length - 1].content = this.escapeHtml(assistantMessage);
            
            // 滚动到底部
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          }
        } catch (error) {
          console.error('Error:', error);
          this.messages.push({
            role: 'system',
            content: '发生错误，请稍后重试。'
          });
        } finally {
          this.isLoading = false;
          this.scrollToBottom();
        }
      },
      scrollToBottom() {
        const container = this.$refs.messagesContainer;
        container.scrollTop = container.scrollHeight;
      }
    }
  };
  </script>
  
  <style scoped>
  .chat-container {
    width: 85%;
    margin: 0 auto;
    padding: 2rem;
    min-height: 100vh;
    background-color: #1a1a1a;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding-top: 3rem;
  }
  
  .header h1 {
    margin-left: 1rem;
    color: #4CAF50;
    font-size: 2rem;
  }
  
  .chat-box {
    background-color: #2d2d2d;
    border-radius: 10px;
    padding: 2rem;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .message {
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 85%;
    line-height: 1.6;
  }
  
  .message.user {
    background-color: #4CAF50;
    color: white;
    margin-left: auto;
  }
  
  .message.assistant {
    background-color: #424242;
    color: #e0e0e0;
    margin-right: auto;
  }
  
  .message.system {
    background-color: #f44336;
    color: white;
    margin: 0 auto;
    text-align: center;
    max-width: 60%;
  }
  
  .input-area {
    display: flex;
    gap: 1.5rem;
    padding: 1.5rem;
    background-color: #363636;
    border-radius: 8px;
    margin-top: auto;
  }
  
  textarea {
    flex-grow: 1;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    background-color: #424242;
    color: #e0e0e0;
    resize: none;
    font-size: 1rem;
    line-height: 1.5;
    min-height: 60px;
  }
  
  button {
    padding: 0 2rem;
    border: none;
    border-radius: 8px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    font-weight: 500;
    min-width: 100px;
  }
  
  button:hover:not(:disabled) {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  button:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
  
  /* 自定义滚动条样式 */
  .messages::-webkit-scrollbar {
    width: 10px;
  }
  
  .messages::-webkit-scrollbar-track {
    background: #1a1a1a;
    border-radius: 5px;
  }
  
  .messages::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 5px;
  }
  
  .messages::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* 消息内容样式 */
  :deep(.message-content) {
    line-height: 1.8;
    font-size: 1rem;
  }
  
  :deep(.message-content pre) {
    background-color: #1a1a1a;
    padding: 1.5rem;
    border-radius: 8px;
    overflow-x: auto;
    margin: 1rem 0;
  }
  
  :deep(.message-content code) {
    background-color: #1a1a1a;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    font-family: monospace;
  }
  
  /* 响应式设计 */
  @media (max-width: 768px) {
    .chat-container {
      width: 95%;
      padding: 1rem;
    }
  
    .message {
      max-width: 90%;
    }
  
    .input-area {
      padding: 1rem;
    }
  }
</style>