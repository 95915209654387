<template>
  <div class="contact-container">
    <BackHomeButton />
    <h1 class="title">联系我们</h1>
    <div class="contact-content">
      <div class="qr-code">
        <div class="qr-code-wrapper">
          <img :src="require('@/assets/images/wechat.png')" alt="微信二维码">
        </div>
        <p>微信聊天更方便，请扫描二维码添加我的微信吧！</p>
      </div>
      <div class="contact-details">
        <h2>联系方式</h2>
        <ul>
          <li>
            <strong>微信号:</strong>
            <span class="wechat-id">gnayji</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BackHomeButton from '@/components/BackHomeButton.vue'

export default {
  name: 'ContactPage',
  components: {
    BackHomeButton
  }
}
</script>

<style scoped>
.contact-container {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0;
  padding: 3rem;
  background-color: #1e1e1e;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.title {
  color: #4caf50;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.contact-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.qr-code {
  text-align: center;
  margin: 1rem;
  transition: transform 0.3s ease;
}

.qr-code:hover {
  transform: translateY(-5px);
}

.qr-code-wrapper {
  background: linear-gradient(145deg, #4caf50, #45a049);
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
}

.qr-code img {
  width: 200px;
  height: 200px;
  border: 3px solid #fff;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.qr-code img:hover {
  transform: scale(1.05);
}

.qr-code p {
  margin-top: 1.5rem;
  color: #b0b0b0;
  font-size: 1.1rem;
  line-height: 1.6;
}

.contact-details {
  margin: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #4caf50;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 2px solid #4caf50;
  padding-bottom: 0.5rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 1rem;
  color: #e0e0e0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

strong {
  color: #4caf50;
  margin-right: 0.5rem;
  font-weight: 600;
}

.wechat-id {
  background-color: rgba(76, 175, 80, 0.1);
  padding: 0.3rem 0.8rem;
  border-radius: 5px;
  font-family: monospace;
  letter-spacing: 1px;
}

@media (max-width: 600px) {
  .contact-content {
    flex-direction: column;
  }
  
  .contact-container {
    padding: 2rem;
  }
  
  .title {
    font-size: 2.5rem;
  }
}
</style>