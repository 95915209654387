<template>
  <div class="login-page">
    <h1>登录</h1>
    <form @submit.prevent="login">
      <input v-model="username" type="text" placeholder="用户名" required>
      <input v-model="password" type="password" placeholder="密码" required>
      <button type="submit">登录</button>
    </form>
    <p v-if="error" class="error-message">{{ error }}</p>
    <!-- <router-link to="/contact" class="register-link">注册用户请联系站长</router-link> -->
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      error: ''
    }
  },
  methods: {
    async login() {
      this.error = ''
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/login`, {
          username: this.username,
          password: this.password
        }, {
          withCredentials: true
        })
        if (response.data && response.data.access_token) {
          localStorage.setItem('access_token', response.data.access_token)
          this.$router.push(this.$route.query.redirect || '/')
        } else {
          this.error = '登录失败，请重试'
        }
      } catch (error) {
        console.error('登录过程中发生错误:', error)
        this.error = error.response?.data?.error || '登录过程中发生错误，请稍后重试'
      }
    }
  }
}
</script>
<style scoped>
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1e1e1e;
  color: #e0e0e0;
}
h1 {
  margin-bottom: 2rem;
  color: #4caf50;
}
form {
  display: flex;
  flex-direction: column;
  width: 300px;
}
input, button {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #e0e0e0;
}
button {
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
button:hover {
  background-color: #45a049;
}
.error-message {
  color: #ff6b6b;
  margin-top: 1rem;
}
.register-link {
  margin-top: 1rem;
  color: #4caf50;
  text-decoration: none;
  transition: color 0.3s;
}
.register-link:hover {
  color: #45a049;
}
</style>